import React from 'react'
import {Link} from 'react-router-dom'

function ButtonLink({url, text}) {
  return (
    <div  className='Button w-[200px]'>
          <Link to={url}>{text}</Link>
    </ div>
    // <button onClick={() => window.open(url, '_blank')} className='Button w-[200px]'>{text}</button>
  )
}

export default ButtonLink