import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function Consulting() {

    const form = useRef();

const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_mbpl48i', 'template_xl8lm3k', form.current, {
        publicKey: 'HVzQHCfEGTPUVOFWp',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

    form.current.reset();
};

    return (
        <div>
            <h1 className='Header'>Consulting Services</h1>
            <div className='flex flex-col lg:flex-row mx-auto px-5'>
                <div className='lg:w-1/2 m-5'>
                    <p className='text-white text-xl'>
                        I draw from over a decade of experience in game development and product management. I offer consulting services to help you successfully launch your project on time 
                        and within budget. My guidance will allow you to set your project apart from the crowd, and I will help you avoid common pitfalls of product development. I will 
                        ensure you have everything needed for launch.
                    </p>
                    <ul className='list-disc ml-5'>
                        <li className='text-white'><span className='text-lg text-cyan-300'>Game Design & Prototyping:</span> Crafting engaging gameplay and rapid prototyping for testing.</li>
                        <li className='text-white'><span className='text-lg text-cyan-300'>Technical Guidance:</span> Advising on software architecture, engines, and optimization.</li>
                        <li className='text-white'><span className='text-lg text-cyan-300'>Branding & Positioning:</span> Building strong, resonant brand identities.</li>
                        <li className='text-white'><span className='text-lg text-cyan-300'>Market Analysis:</span> Analyzing trends and user needs to inform decisions.</li>
                        <li className='text-white'><span className='text-lg text-cyan-300'>Product Development:</span> Defining vision, goals, and actionable roadmaps.</li>
                        <li className='text-white'><span className='text-lg text-cyan-300'>Quality Assurance & Code Review:</span> Enhancing stability and performance across platforms.</li>
                    </ul>
                </div>
                <form ref={form} onSubmit={sendEmail} className='bg-white p-5 rounded-xl flex flex-col lg:w-1/2'>
                    <h2 className='font-comfortaa text-2xl text-center'>Request a Quote</h2>
                    <div className='flex justify-between items-center mb-4'>
                        <label htmlFor="name" className='mr-2'>Name: </label>
                        <input type="text" id="name" name="name" required className='border-2 border-slate-500 rounded-md w-5/6'/>
                    </div>
                    <div className='flex justify-between items-center mb-4'>
                        <label htmlFor="email" className='mr-2'>Email: </label>
                        <input type="email" id="email" name="email" required className='border-2 border-slate-500 rounded-md w-5/6'/>
                    </div>
                    <div className='flex justify-between items-center mb-4'>
                        <label htmlFor="service" className='mr-2'>Service: </label>
                        <input type="text" id="service" name="service" required className='border-2 border-slate-500 rounded-md w-5/6'/>
                    </div>
                    <div className='flex justify-between items-center mb-4'>
                        <label htmlFor="message" className='mr-2'>Message: </label>
                        <textarea id="message" name="message" required className='border-2 border-slate-500 rounded-md w-5/6 h-60'></textarea>
                    </div>
                    <button type="submit" className='Button'>Submit</button>
                </form>
            </div>
        </div>
    );
}

export default Consulting