import {React, useState} from 'react'
import {Link} from 'react-router-dom'
import Logo from './Logo'

const navigation = [
    { name: 'Home', href: '/home' },
    { name: 'Projects', href: '/projects' },
    { name: 'About Us', href: '/about' },
    { name: 'Contact', href: '/contact' },
  ]

function Header() {
    const [mobileMenu, setMobileMenu] = useState(false)

    return (
    <header className='bg-blue-900 w-screen font-comfortaa'>
        <nav className="mx-auto flex text-md lg:text-xl items-center justify-between p-2 lg:px-8 border-b-2 border-cyan-300">
            <Logo />
             <div> {/* Hamburger Menu */}
                <button
                    type="button"
                    onClick={() => setMobileMenu(!mobileMenu)}
                    className='text-3xl align-bottom md:hidden mr-5 text-white'
                >&#9776;
                </button>
            </div>
            <div className='hidden md:flex md:flex-row'>
                <div className="text-xl justify-center  text-white">
                    {navigation.map((item) => (
                        <Link
                            key={item.name}
                            to={item.href}
                            className='m-3 lg:m-5 hover:text-cyan-300 hover:scale-105'
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>
                <div className='border-r-2 border-cyan-300' />
                <div className='justify-center'>
                    <Link
                        to="./booking"
                        className='text-xl m-5 text-cyan-300 hover:text-white hover:scale-110'
                    >
                        Book a Session
                    </Link>
                </div>
            </div>
        </nav>
        {/* Mobile Menu */}
        {mobileMenu && (
            <div className="lg:hidden">
                <div className="fixed inset-0 z-10" />
                <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">JimDublace Studios</span>
                            {/* <Logo /> */}
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenu(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            Close
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                            <div className="py-6">
                                <Link
                                    to="/booking"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    onClick={() => setMobileMenu(false)}
                                >
                                    Book a Session
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </header>
  )
}

export default Header