import React, {useEffect, useState} from 'react'

function Service({url}) {

  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script); // Cleanup the script when the component unmounts
        };
      }, []);

  return (
    <div>
        {/* <p className='text-2xl text-black'>Test</p> */}
            <div id='calendly'
                className="calendly-inline-widget"
                data-url={url}
                style={{ minWidth: '320px', height: '700px' }}
            ></div>
    </div>
  )
}

export default Service