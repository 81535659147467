import React from 'react'
import LogoArt from "../Images/Logo.png"

function Logo() {
  return (
    <div className="flex flex-col items-center text-cyan-300 hover:text-white hover:scale-110">
        <a href="/" className="-m-1.5 p-1.5">
            <img src={LogoArt} alt="/home" className='h-12 lg:h-16 w-auto'/>
        </a>
        {/* <h2 className='font-comfortaa hidden lg:flex'>JimDublace Studios </h2> */}
    </div>
  )
}

export default Logo