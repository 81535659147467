import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import Header from './Components/Header'
import Home from './Pages/Home'
import About from './Pages/About'
import Booking from './Pages/Booking'
import Projects from './Pages/Projects'
import Contact from './Pages/Contact'
import Footer from './Components/Footer'
import Consulting from './Pages/Consulting';


function App() {
  return (
    <div className="bg-indigo-800">
      <header>
        <Router>
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/booking' element={<Booking />} />
            <Route path='/projects' element={<Projects />} /> 
            <Route path='/contact' element={<Contact />} />
            <Route path='/consulting' element={<Consulting />} />
          </Routes>
          <Footer />
        </Router>
      </header>
    </div>
  );
}

export default App;
