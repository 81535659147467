import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { faBluesky, faDiscord, faFacebook, faInstagram, faLinkedin, faReddit, faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Contact() {
  const form = useRef();

const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_2devjey', 'template_po4f9ni', form.current, {
        publicKey: 'HVzQHCfEGTPUVOFWp',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

    form.current.reset();
};

const socialChannels = [
    { name: 'Facebook', href: 'https://www.facebook.com/jimdublace', icon: faFacebook },
    { name: 'Instagram', href: 'https://www.instagram.com/jimdublace/', icon: faInstagram },
    { name: 'BlueSky', href: 'https://bsky.app/profile/jimdublace.bsky.social', icon: faBluesky },
    { name: 'LinkedIn', href: 'https://www.linkedin.com/company/jimdublace-studios/about', icon: faLinkedin },
    { name: 'YouTube', href: 'https://www.youtube.com/@jimdublace', icon: faYoutube },
    { name: 'Reddit', href: 'https://www.reddit.com/user/jimdublace', icon: faReddit },
    { name: 'Twitch', href: 'https://www.twitch.tv/jimdublace', icon: faTwitch },
    { name: 'Discord', href: 'https://discord.gg/NPjFd8uT22', icon: faDiscord },

]

return (
    <div className='flex flex-col mx-auto text-white h-screen w-screen'>
        <h1 className='flex justify-center font-comfortaa text-2xl md:text-5xl text-cyan-300 pt-10'>Find us on Social Media</h1>
        <div className='flex flex-row mx-auto text-cyan-300 text-2xl'>
          {socialChannels.map((channel) => (
            <Link
              key={channel.name}
              to={channel.href}
              target="_blank"
              rel="noopener noreferrer"
              className='flex flex-row justify-center p-2 md:p-5'
            >
              <FontAwesomeIcon icon={channel.icon} className='hover:scale-110 hover:text-cyan-200' />
            </Link>
          ))}
        </div>
        
        <h1 className='flex justify-center text-center font-comfortaa text-2xl md:text-2xl text-cyan-300 pt-5'>You Can also Reach us by Email</h1>
        <div className='flex justify-center'>
          <a href="mailto:info@jimdublace.com" className='text-cyan-300 hover:underline'>
            info@jimdublace.com
          </a>
        </div>
        <h1 className='flex justify-center text-center font-comfortaa text-2xl md:text-2xl text-cyan-300 pt-5'>Or fill out this form to drop us a note</h1>

        <form ref={form} onSubmit={sendEmail} className='flex flex-col justify-center px-10'>
          <div className='flex flex-row'>
            <label className='w-1/3 text-right'>Name</label>
            <input type="text" name="from_name" className='border rounded-md m-2  w-2/3'/>
          </div>
          <div className='flex flex-row'>
            <label className='w-1/3 text-right'>Email</label>
            <input type="email" name="user_email" className='border rounded-md m-2 w-2/3'/>
          </div>
          <div className='flex flex-row'>
            <label className='w-1/3 text-right'>Message</label>
            <textarea name="message" className='border rounded-md m-2 w-2/3 h-48'/>
          </div>
          <button className='Button w-1/3 self-end' type='submit'>Send</button>        
        </form>
    </div>
    
  );
};

export default Contact
