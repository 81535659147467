import React from 'react'
import Service from '../Components/Service'

function Booking() {

  const serviceURL = "https://calendly.com/jimdublace/mentoring"
  const consultURL = "https://calendly.com/jimdublace/mentoring"

  return (
    <div>
      <h1 className='flex justify-center text-5xl font-comfortaa p-5 text-cyan-300'>Services</h1>
      <Service url={serviceURL} />
      {/* <Service url={consultURL} /> */}
    </div>
  )
}

export default Booking