import React from 'react'
import Logo from '../Images/Logo.png'

function Footer() {
    const footerYear = new Date().getFullYear()

  return (
    <footer className="footer p-2 bg-blue-900 text-white pt-4">
      <div className='flex flex-col md:flex-row justify-start md:justify-around mx-auto md:items-end'>
        <div className=''>
          <div className='flex flex-col items-center'>
            <a href='/'>
              <img 
                  src={Logo}
                  className='h-14 w-14 hover:scale-105 justify-center'
              />              
            </a>
            <h2 className='font-comfortaa text-teal-300'>JimDublace Studios</h2>
          </div>
          
        </div>
        <p className='pt-5'>Copyright &copy; {footerYear} All rights reserved</p>
        <div className='flex my-4 border-white'>
          <div className='flex flex-col'>
            <a href='mailto:info@jimdublace.com' className='hover:scale-105 hover:text-blue-300'>info@jimdublace.com</a>
            <div className='flex border-b-2 border-white py-1' />
            <a href='https://maps.google.com/?q=5 Market St, Amesbury, Massachusetts 01913' className='hover:text-blue-300 hover:scale-105'>
              <div>5 Market Square Ste. 206A <br/>Amesbury, MA 01913</div>
            </a>
          </div>
        </div>
        
      </div>
    </footer>
  )
}

export default Footer