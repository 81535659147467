import { useState, useEffect } from 'react';
import Logo from '../Images/Logo.png'
import ButtonLink from '../Components/ButtonLink'
import codeSnippet from '../Images/codeSnippet.png'

function Home() {
  
  return (
    <div className='h-auto flex flex-col mx-auto w-full'>
      <div className=''>
        {/* Hero Section */}
        <div className='flex bg-black bg-opacity-80 shadow-xl shadow-gray-800'>
          <h1 className='font-comfortaa text-3xl md:text-5xl lg:w-2/3 text-white p-10'>Our mission is to create opportunities for aspiring game developers to find the enjoyment of game development through <span className='text-cyan-400'>Learning</span>, <span className='text-cyan-400'>Mentoring</span>, and <span className='text-cyan-400'>Community</span>.</h1>
          <img src={codeSnippet} alt='Code Snippet' className='w-1/3 hidden lg:flex' style={{ maskImage: 'linear-gradient(to right, transparent, black)' }}/>
        </div>
        {/* Services */}
        <div className='bg-gradient-to-b from-cyan-400 to-indigo-500 bg-opacity-70 shadow-xl shadow-gray-800 px-5'>
          <h2 className='font-comfortaa text-5xl text-center text-black pt-10'>Our Services</h2>
          <div className='flex flex-col lg:flex-row mx-auto'>
            <div className='bg-white p-10 rounded-xl m-2 lg:w-1/3'>
              <h3 className='font-comfortaa text-2xl text-black'>Mentoring/Tutoring</h3>
              <p className='text-black pl-5'>Whether you're a novice eager to learn the basics or an experienced developer 
                  looking to break into the game development industry, I offer personalized guidance tailored to your goals 
                  and expertise level. Through one-on-one sessions, constructive feedback, and setting practical goals, I empower 
                  you to unleash your creativity and achieve your aspirations in the vibrant realm of game development.</p>
              <ButtonLink url='/booking' text='Book a Session'/>
            </div>
            <div className='flex flex-col bg-white justify-between p-10 rounded-xl m-2 lg:w-1/3'>
              <h3 className='font-comfortaa text-2xl text-black'>Game Dev Consulting</h3>
              <p className='text-black pl-5'>Need help developing your next big game idea? Look no further than JimDublace Studios for expert game development 
                  consulting. From concept creation to technical advice, we'll guide you through the process and help bring your game to life. Competitive pricing and fast delivery allow you to keep your project on budget and on time. Let's turn your 
                  vision into a reality together.</p>
              <ButtonLink url='/consulting' text='Learn More'/>
            </div>
            <div className='flex flex-col bg-white justify-between p-10 rounded-xl m-2 lg:w-1/3'>
              <h3 className='font-comfortaa text-2xl text-black'>Marketing Consulting</h3>
              <p className='text-black pl-5'>I draw from over a decade of experience in product development and market analysis to help you successfully 
                  launch your project on time and within budget. My guidance will allow you to set your project apart from the crowd, and I will help you avoid common pitfalls of product development. I will ensure you have everything needed for launch.</p>
              <ButtonLink url='/consulting' text='Learn More'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home