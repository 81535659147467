import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'

function About() {
  return (
    <div className='text-white text-xl lg:h-screen'>
      <h1 className='Header'>About JimDublace Studios</h1>
      <p className='p-5'>
        At JimDublace Studios, we believe that game development is more than just a career—it's a passion. 
          Founded in 2022 by a Navy veteran with over a decade of programming and game development experience, 
          our studio is dedicated to fostering the next generation of game developers. Our mission is clear: to 
          create opportunities for aspiring game developers to discover the joy and fulfillment of game development 
          through Learning, Mentoring, and Community.
      </p>
      <p className='p-5'>
        With over 20 years of hands-on experience in a variety of industries, we offer a unique perspective and expertise in 
          both programming and creative development. Our founder's background in the Navy has instilled a strong sense of 
          discipline, teamwork, and resilience, values that are at the heart of our studio's approach.
      </p>
      <p className='p-5'>
        JimDublace Studios is committed to providing a variety of services designed to help individuals grow in their craft. 
          Whether you’re just starting out or looking to hone your skills, we offer personalized guidance, resources, and a supportive 
          community where you can learn, collaborate, and thrive.
      </p>
      <div className='flex flex-col items-center pt-5 '>
        <h1 className='font-comfortaa text-cyan-300 pl-10 text-3xl'>Want to know more?</h1>
        <p className='flex text-center p-5 pt-0 w-2/3'>Join our Discord server to connect with other game developers, share your work, and get feedback from the community.</p>
        <a href='https://discord.gg/NPjFd8uT22' className='Button p-5 text-black'><FontAwesomeIcon icon={faDiscord} />Join Now</a>
      </div>
      
    </div>
  )
}

export default About