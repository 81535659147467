import React from 'react'



function Project({ Title, Description, Image, url }) {
  return (
    <div key={Title} className='flex flex-row text-black bg-white p-10 w-full md:w-3/4 lg:w-2/3 md:mx-auto border-y-2 border-indigo-600'>
      <div className='flex-row'>
        <p className='text-2xl font-comfortaa'>{Title}</p>
        <div className='border-b-2 w-1/2 border-black' />
        <p className='text-md p-2 pb-5'>{Description}</p>
        <a href={url} target='_blank' rel='noreferrer' className='Button p-5'>Check it out</a>
      </div>
      <img src={Image} alt={Title} className='rounded-xl w-[100px] h-fit self-start hidden sm:flex' />
    </div>
  )

}

export default Project